@import 'styles/layers.scss';

@layer uikit {
  .root {
    background-color: transparent;
    width: auto;
    height: auto;
    padding: 0;
    border: none;
      background-color: transparent;
      color: var(--white);
    &:hover:not(.disabled) {
      background-color: transparent;
    }
    &.active {
      color: transparent
    }
  }
}
