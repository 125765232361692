@import 'styles/layers.scss';

@layer uikit {
  .content {
    width: 100%;
    padding: 12px 44px 12px 12px;
    font-size: 12px;
    background: var(--yellow1);
    position: relative;
    margin-top: 5px;
    border-radius: 4px;
  }

  .icon-close {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 7px);
    right: 14px;
    color: var(--white3);
  }

  .children {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .childrenHtml {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    hyphens: auto;
    color: inherit;
    white-space: unset !important;
  }

  .childrenHtml > span > a, .childrenHtml > span > a:visited, .childrenHtml > span > a:hover {
    color: inherit;
    font-weight: 500;
  }

  .dark {
    &.childrenHtml > span > a, &.childrenHtml > span > a:visited, &.childrenHtml > span > a:hover {
      color: var(--white);
    }
  }

  .text-group {
    color: var(--yellow);
    margin-left: 20px;
  }

  .icon-info {
    margin-right: 7px;
    margin-top: 3px;
  }

  .content:not(:first-child) {
    margin-top: 6px;
  }

  @media screen and (max-width: 1120px) {
    .childrenHtml {
      width: 650px;
    }
  }

  @media screen and (max-width: 1000px) {
    .childrenHtml {
      width: unset;
      flex: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 12px 15px;
    }
  }

  @media screen and (max-width: 468px) {
    .content {
      padding: 8px;
    }
  }
}
