@layer component {
  .skel {
    height: 35px;
    min-height: 35px;
    width: 100%;
  }

  .lines {
    width: 100%;
    row-gap: 4px;
  }

  .dark {
    .lineChildrenBase {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .link, .link:visited, .link:hover {
    text-decoration: underline;
    color: inherit !important;
    cursor: pointer;
  }

  .paragraph {
    margin: 0 !important;
    color: var(--yellow);
  }
}